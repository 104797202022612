<template>
  <div class="d-flex">
    <CyMenu offset-y>
      <template #activator="{ on }">
        <span>
          <CyButton
            theme="primary"
            variant="tertiary"
            icon="mdi-filter-variant"
            data-cy="sort-button"
            icon-prepend
            class="sort-by"
            v-on="on">
            {{ sortLabel(currentSort) }}
          </CyButton>
        </span>
      </template>
      <v-list>
        <v-list-item
          v-for="sort in sorts"
          :key="sort.label"
          :class="{ 'sort-list-item--active': sortLabel(currentSort) === sortLabel(sort) }"
          data-cy="sort-list-item"
          @click="sortBy(sort)">
          <v-list-item-title>{{ sortLabel(sort) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </CyMenu>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

/**
 * Sorts component displays a dropdown for the sorts dropdown in CyDataTable and pages that use the v-data-iterator
 *
 * @param {Array}    sorts          An array (required) of objects containing the options array for each sort. I.e:
 *                                  [
                                      { sortBy: ['created_at'], sortDesc: [false] },
                                      { sortBy: ['created_at'], sortDesc: [true] },
                                      { sortBy: ['updated_at'], sortDesc: [true] },
                                    ]
 *                                    `sortBy`: (required) determines which queryParam to sort by.
 *                                    `sortDesc`: (required) Can be true|false. determines the sort direction
 *
 *  `sortBy` is based on the keys of the sorted objects and not the query params!
 *  `sortLabel` is a bit magical as it does a lookup in src/locales/sorts.js based on the `sortBy` and `sortDesc` props
 *   The `sortBy` and `sortDesc` are arrays because it's a Vuetify's requirement as [it supports multi sorting](https://vuetifyjs.com/en/api/v-data-iterator/#options)
 */
export default {
  name: 'CyDataTableSorts',
  props: {
    sorts: {
      type: Array,
      validator: (sorts) => _.isEmpty(sorts) ||
                            _.every(sorts, (sort) => _.$hasAll(sort, ['sortBy', 'sortDesc']) &&
                            _.isArray(sort.sortBy) && !_.isEmpty(sort.sortBy) &&
                            _.isArray(sort.sortDesc) && !_.isEmpty(sort.sortDesc)),
      required: true,
    },
  },
  data: () => ({
    currentSort: {
      sortBy: ['sortBy'],
    },
  }),
  computed: {
    ...mapGetters('layout', [
      'getDataTableProps',
    ]),
  },
  created () {
    const defaultSortOptions = _.pick(this.getDataTableProps(this.$route.name), ['sortBy', 'sortDesc'])
    const isSortAlreadyActive = !_.isEmpty(defaultSortOptions.sortBy)
    if (isSortAlreadyActive) this.currentSort = defaultSortOptions
  },
  methods: {
    ...mapMutations('layout', [
      'SET_DATA_TABLE_PROPS',
    ]),
    sortBy (sortOptions) {
      const name = this.$route.name
      this.SET_DATA_TABLE_PROPS({ name, props: { ...this.getDataTableProps(name), ...sortOptions } })
      this.currentSort = sortOptions
    },
    sortLabel ({ sortBy = [], sortDesc = [] }) {
      return sortBy[0] === 'sortBy'
        ? this.$t(sortBy[0])
        : this.$t(`sorts.${_.camelCase(sortBy[0])}${sortDesc[0] ? 'Descending' : 'Ascending'}`)
    },
  },
  i18n: {
    messages: {
      en: {
        sortBy: 'Sort by',
      },
      es: {
        sortBy: 'Ordenar por',
      },
      fr: {
        sortBy: 'Trier par',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .sort-by.cy-btn .v-icon {
  font-size: 22px !important;
}

.sort-list-item {
  &--active {
    color: get-color("accent");
  }
}
</style>
